import http from '@/utils/http'

// 新建|编辑公告
export function addNotice (p) {
  return http.post('/material/topi/addOrUpdateTopi', p)
}

// 删除公告
export function delNotice (p) {
  return http.get('/material/topi/deleteTopi', p)
}

// 公告列表
export function getNoticeList (p) {
  return http.get('/material/topi/getTopiList', p)
}
