<template>
  <PageContainer>
    <BaseSearchForm
      @search="onSearch"
      @reset="onReset"
      :loading="loading">
      <el-form-item :label="getPlaceholder+':'">
        <el-input v-model.trim="searchForm.title" placeholder="请输入" clearable/>
      </el-form-item>
    </BaseSearchForm>
    <BaseTable
      :id="'notice_'+$route.params.type"
      @page-change="handlePageChange"
      :tablePage="tablePage"
      :loading="loading"
      :data="tableData">
      <template #buttons>
        <el-button type="primary" icon="el-icon-plus" @click="addVisible = true">新 增</el-button>
      </template>
      <vxe-column type="seq" title="序号" width="80" align="center" />
      <vxe-column field="orgaName" title="发布机构" min-width="150" />
      <vxe-column field="title" :title="getPlaceholder" min-width="150" />
      <vxe-column field="createName" title="创建人" min-width="150" />
      <vxe-column field="issuDatTim" title="发布时间" min-width="170" />
      <!-- <vxe-column field="readCount" title="查看次数" min-width="120"></vxe-column> -->
      <vxe-column title="操作" min-width="120">
        <template #default="{ row }">
          <el-button type="primary" title="预览附件" @click="previewPdf(row)">
            预览附件
          </el-button>
          <el-button type="danger" title="删除" @click="deleteRole(row.roleId)">
            删除
          </el-button>
        </template>
      </vxe-column>
    </BaseTable>

    <add-notice :visible.sync="addVisible" @refresh="initList" />
  </PageContainer>
</template>

<script>
import { delNotice, getNoticeList } from '@/apis/notice'
import AddNotice from './components/AddNotice'
export default {
  components: {
    AddNotice
  },
  data () {
    return {
      searchForm: {
        title: '', // 标题
        topiType: '' // 类型
      },
      // 列表相关
      loading: false,
      tableData: [],
      tablePage: {
        pageNum: 1,
        pageSize: 20,
        totalSize: 0
      },
      addVisible: false
    }
  },
  computed: {
    getPlaceholder () {
      const placeholders = {
        1: '行业通告',
        10: '通知公告'
        // 20: '发文公示',
      }
      return placeholders[this.searchForm.topiType]
    }
  },
  watch: {
    '$route.params': {
      deep: true,
      handler ({ type }, { oldType }) {
        if (type !== oldType) {
          this.initPage()
        }
      }
    }
  },
  created () {
    this.initPage()
  },
  methods: {
    onReset () {
      const source = this.$options.data(this).searchForm
      this.searchForm = Object.assign({}, this.searchForm, source)
      this.onSearch()
    },

    initPage () {
      this.searchForm.topiType = this.$route.params.type
      this.initList()
    },

    onSearch () {
      this.tablePage.pageNum = 1
      this.initList()
    },

    initList () {
      this.loading = true
      const { tablePage } = this
      const { totalSize, ...pageParams } = this.tablePage
      const page = {
        currentPage: pageParams.pageNum,
        pageSize: pageParams.pageSize
      }
      const params = Object.assign({}, this.searchForm, page)
      getNoticeList(params).then((res) => {
        this.loading = false
        if (res.code == 200) {
          this.tableData = res.data.rows
          tablePage.totalSize = res.data.totalSize
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    handlePageChange ({ currentPage, pageSize }) {
      this.tablePage.pageNum = currentPage
      this.tablePage.pageSize = pageSize
      this.initList()
    },

    previewPdf ({ acceFilURL }) {
      if (acceFilURL) {
        // getMediaUrl 是全局mixin
        window.open(this.getMediaUrl(acceFilURL), '_blank')
      } else {
        this.$message.error('没有可以预览的pdf')
      }
    },

    deleteRole (id) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = { ids: [id] }
        delNotice(params).then((res) => {
          if (res.code == 200) {
            this.initList()
            this.$message.success('刪除成功！')
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    }
  }
}
</script>
