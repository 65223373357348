<template>
  <div class="flex align-center" v-loading="loading">
    <el-upload
      v-if="!reload"
      ref="uploadRef"
      :action="url"
      :on-change="uploadSuccess"
      :http-request="uploadPicture"
      :show-file-list="false"
      :limit="1">
      <el-button size="small" type="primary">点击上传</el-button>
    </el-upload>
    <template v-if="src">
      <el-link class="ml-10" @dblclick.native="downloadMedia" target="_blank" type="info" title="双击预览附件">
        <i class="el-icon-link"></i>
        已传附件
      </el-link>
      <el-icon class="delete-pdf el-icon-delete ml-5" @click.native="$emit('update:src', '')"></el-icon>
    </template>
  </div>
</template>

<script>
import http from '@/utils/http'
export default {
  props: {
    src: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      url: http.baseUrl + '/basefun/fdfscs/uploadOne',
      loading: false,
      reload: false // 刷新 保证可以重复选同一个文件
    }
  },
  computed: {
    hidePicker () {
      return this.limit == 1 && !!this.src
    }
  },
  methods: {
    downloadMedia () {
      window.open(this.getMediaUrl(this.src))
    },
    // 上传图片
    uploadPicture (content) {
      this.loading = true
      const formData = new FormData()
      formData.append('file', content.file)
      http.postForm(this.url, formData).then((res) => {
        this.$emit('uploaded', res.data)
        this.loading = false
      })
    },

    uploadSuccess () {
      console.log('uploadSuccess')
      this.reload = true
      this.$nextTick(() => {
        this.reload = false
      })
    }

  }
}
</script>

<style lang="scss" scoped>
.delete-pdf {
  &:hover {
    cursor: pointer;
    color: darkred;
  }
}
.upload-picture {
  position: relative;
  width: 120px;
  height: 120px;

  .upload-picker {
    width: 120px;
    height: 120px;
    background-color: #fbfdff;
    border: 1px dashed #c0ccda;
    border-radius: 6px;
    vertical-align: top;
    cursor: pointer;

    // &:hover {
    //   border-color: #999;
    // }
  }

  .picture-thumb {
    position: relative;
    width: 120px;
    height: 120px;
    background-color: #fff;
    border: 1px solid #c0ccda;
    border-radius: 6px;
    overflow: hidden;

    .picture-item {
      width: 100%;
      height: 100%;
      object-fit: contain;
      display: block;
    }

    &:hover {
      border-color: transparent;
      .picture-actions {
        display: flex;
      }
    }

    .picture-actions {
      position: absolute;
      display: none;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      color: #fff;
      font-size: 20px;
      background-color: rgba(0,0,0,.5);
    }

    .picture-actions > i {
      cursor: pointer;
    }
  }

  .el-icon-plus {
    color: #8c939d;
  }

}
</style>
