<template>
  <el-dialog
    v-bind="{title:'新增'+currTitle,width:'430px'}"
    v-dialogDrag
    @open="handlerOpen"
    @closed="handlerClose"
    :visible.sync="visibleDialog">
    <el-form ref="form" label-width="105px" :model="submitForm" :rules="rules">
      <el-form-item :label="currTitle" prop="title">
        <el-input class="w-250" v-model.trim="submitForm.title" clearable></el-input>
      </el-form-item>
      <el-form-item label="公告内容" prop="acceFilURL">
        <upload-pdf :src="submitForm.acceFilURL" @uploaded="uploaded" />
      </el-form-item>
    </el-form>
    <div v-if="submitForm.acceFilURL" class="flex justify-center">
      <iframe :src="getMediaUrl(submitForm.acceFilURL)" frameborder="0" />
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visibleDialog=false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="loading">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import UploadPdf from '@/components/UploadPdf'
import { addNotice } from '@/apis/notice'

export default {
  props: {
    visible: { type: Boolean, default: false }
  },
  computed: {
    visibleDialog: {
      set (newValue) {
        this.$emit('update:visible', newValue)
      },
      get () { return this.visible }
    },
    currTitle () {
      return this.titles[this.submitForm.topiType]
    }
  },
  data () {
    return {
      titles: { 1: '行业通告', 10: '通知公告' },
      submitForm: {
        title: '',
        topiType: '', // 类型 1:公告 10:通知 20:发文公示
        acceFilURL: '', // 附件地址
        createName: '' // 创建人
      },
      rules: {
        title: { required: true, trigger: 'change', message: '请输入' },
        acceFilURL: { required: true, message: '请上传内容附件' }
      },
      loading: false
    }
  },
  methods: {
    uploaded ({ nowRelatUrl }) {
      this.submitForm.acceFilURL = nowRelatUrl
    },

    // 打开弹窗
    handlerOpen () {
      this.submitForm.topiType = this.$route.params.type
      this.submitForm.createName = this.$store.state.account.userInfo.userName || undefined
    },

    // 关闭弹窗
    handlerClose () {
      const sourceForm = this.$options.data(this).submitForm
      this.submitForm = Object.assign({}, this.submitForm, sourceForm)
      this.$emit('update:editData', null)
      this.$refs.form.resetFields()
    },

    submit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          addNotice(this.submitForm).then((res) => {
            this.loading = false
            if (res.code == 200) {
              this.$message.success('创建成功！')
              this.$emit('refresh')
              this.visibleDialog = false
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          return false
        }
      })
    }
  },
  components: {
    UploadPdf
  }
}
</script>
